import { Button, Card } from '@energiebespaarders/symbols';
import { Color, Heading } from '@energiebespaarders/symbols/helpers';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useStartFunnel } from '~/hooks/useFunnel';
import { StartIntakeBlockFragment } from '~/types/generated/cms/StartIntakeBlockFragment';
import { DutyPackageName } from '~/types/graphql-global-types';
import AddressInitForm from '../RequestPackage/AddressInitForm';

interface IStartIntakeCardProps {
  block: StartIntakeBlockFragment;
}

const StartIntakeCard: React.FC<IStartIntakeCardProps> = ({ block }) => {
  const { title, titleColor, dutyPackage, voucherCode, buttonText, bgColor, buttonColor } = block;

  const [addressFromParams, setAddressFromParams] = useState<{
    zip: string;
    number: number;
    suffix?: string;
  }>({ zip: '', number: 0 });

  const [selectedHouseId, setSelectedHouseId] = useState('');

  const router = useRouter();
  const params = router.query;

  useEffect(() => {
    if (params?.postcode && params?.huisnummer && !addressFromParams) {
      const { postcode, huisnummer, toevoeging } = params as {
        postcode: string;
        huisnummer: string;
        toevoeging: string;
      };
      setAddressFromParams({
        zip: postcode.toUpperCase(),
        number: parseInt(huisnummer, 10),
        suffix: toevoeging,
      });
    }
  }, [addressFromParams, params]);

  const { startFunnel, loading } = useStartFunnel({
    dutyPackage: dutyPackage?.key ?? DutyPackageName.complete,
    couponCode: voucherCode?.code ?? undefined,
  });

  return (
    <Card bgColor={bgColor?.hex}>
      {title && (
        <Heading heading="h3">
          <Color c={titleColor?.hex}>{title}</Color>
        </Heading>
      )}
      <AddressInitForm
        isActive
        initialValues={addressFromParams}
        selectedHouseId={selectedHouseId}
        setSelectedHouseId={setSelectedHouseId}
        buttonLabel={buttonText ?? 'Gewoon beginnen →'}
        buttonIcon={null}
        buttonBgColor={buttonColor?.hex ?? 'yellow'}
        onHouseInitialized={startFunnel}
        buttonColor="grayBlack"
      />
      {selectedHouseId && (
        <Button
          onClick={startFunnel}
          bgColor={buttonColor?.hex ?? 'yellow'}
          color="grayBlack"
          label={buttonText ?? 'Gewoon beginnen →'}
          fluid
          loading={loading}
        />
      )}
    </Card>
  );
};

export default StartIntakeCard;
