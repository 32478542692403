import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useDebounce, useForm } from '@energiebespaarders/hooks';
import { isInvalidEmail } from '@energiebespaarders/hooks/useForm';
import { Box, Button, Card, Flex, Input } from '@energiebespaarders/symbols';
import { Center } from '@energiebespaarders/symbols/helpers';
import { useEffect, useState } from 'react';
import { MeCustomerFragment } from '~/fragments/Me';
import { useActiveHouseId } from '~/hooks/useActiveHouseId';
import useGAEvent from '~/hooks/useGAEvent';
import { useMe } from '~/hooks/useMe';
import { CHECK_EMAIL_EXISTS } from '~/queries/forms';
import { GET_ME } from '~/queries/initialize';
import { checkEmailExists, checkEmailExistsVariables } from '~/types/generated/checkEmailExists';
import { intakeSignup, intakeSignupVariables } from '~/types/generated/intakeSignup';
import { me as meQuery, me_me_Customer, me_me_Lead } from '~/types/generated/me';
import {
  createMarketingConsent,
  createPrivacyConsent,
  createTAndCConsent,
} from '~/utils/createConsent';
import LoginForm from '../AuthenticationForms/LoginForm';
import { MarketingConsentCheckbox } from '../RequestPackage/ConsentCheckbox';

const INTAKE_SIGNUP = gql`
  ${MeCustomerFragment}
  mutation intakeSignup(
    $leadId: ID!
    $houseId: ID!
    $firstName: String!
    $email: String!
    $consent: [ConsentInput!]!
  ) {
    intakeSignup(
      leadId: $leadId
      houseId: $houseId
      firstName: $firstName
      email: $email
      consent: $consent
    ) {
      id
      ...MeCustomer
    }
  }
`;

const MinimalSignupForm: React.FC = () => {
  const { me, setMe } = useMe<me_me_Lead | me_me_Customer>();
  const { activeHouseId } = useActiveHouseId();
  const sendGAEvent = useGAEvent();

  const { refetch: refetchMe } = useQuery<meQuery>(GET_ME, {
    fetchPolicy: 'network-only',
    skip: me?.__typename === 'Customer',
    onCompleted: ({ me }) => me.__typename === 'Customer' && setMe(me),
  });

  const [signUp, { loading, error }] = useMutation<intakeSignup, intakeSignupVariables>(
    INTAKE_SIGNUP,
    {
      onCompleted: () => {
        void refetchMe();
        void sendGAEvent('sign_up', { method: 'email' });
      },
    },
  );

  const [emailExistsError, setEmailExistsError] = useState('');
  const [magicLinkRequested, setMagicLinkRequested] = useState(false);

  const { formState, handleChange, submitForm, errors } = useForm<{
    firstName: string;
    email: string;
    marketingConsent: boolean;
  }>({
    initialValues: { firstName: '', email: '', marketingConsent: false },
    handleSubmit: values =>
      me.__typename === 'Lead' &&
      !loading &&
      signUp({
        variables: {
          ...values,
          leadId: me.id,
          houseId: activeHouseId,
          consent: [
            createTAndCConsent('signup'),
            createPrivacyConsent('signup'),
            ...(values.marketingConsent ? [createMarketingConsent('signup')] : []),
          ],
        },
      }),
    validate: (values, errors) => {
      if (emailExistsError) errors.email = emailExistsError;
      if (!values.firstName) errors.firstName = 'Verplicht';
      if (!values.email) errors.email = 'Verplicht';
      if (isInvalidEmail(values.email)) errors.email = 'Ongeldig';
      if (error) errors.general = 'Er is iets misgegaan. Probeer het later opnieuw.';
      return errors;
    },
  });

  // Check if email already exists
  const debouncedEmail = useDebounce(formState.email.value, 200);
  const [checkEmailExistsMutation, { loading: checkingEmail }] = useLazyQuery<
    checkEmailExists,
    checkEmailExistsVariables
  >(CHECK_EMAIL_EXISTS, {
    onCompleted: ({ checkEmailExists: { emailExists } }) => {
      setEmailExistsError(emailExists ? 'Dit e-mailadres is al in gebruik' : '');
    },
  });

  useEffect(() => {
    if (debouncedEmail && !isInvalidEmail(debouncedEmail) && !checkingEmail) {
      void checkEmailExistsMutation({ variables: { email: debouncedEmail } });
    }
  }, [debouncedEmail, checkingEmail, checkEmailExistsMutation]);

  const [hasExistingAccount, setHasExistingAccount] = useState(false);
  const emailExists = !!emailExistsError && !!formState.email.error;

  return (
    <Card p={2} mt={1} shadow="none" borderColor="grayLight" animation="fadeIn">
      {hasExistingAccount ? (
        <LoginForm
          email={formState.email.value}
          magicLinkRedirect="intake#start"
          onMagicLinkSent={() => setMagicLinkRequested(true)}
        />
      ) : (
        <form id="intakeSignupForm" onSubmit={submitForm}>
          <Flex flexWrap="wrap" mx={-1}>
            <Box width={[1, 1, 1 / 2, 1]} px={1}>
              <Input
                label="Voornaam"
                onChange={e => handleChange({ firstName: e.target.value })}
                addonContent="👋"
                addonSide="start"
                value={formState.firstName.value}
                touched={formState.firstName.touched}
                error={formState.firstName.error}
                autoComplete="given-name"
              />
            </Box>
            <Box width={[1, 1, 1 / 2, 1]} px={1}>
              <Input
                type="email"
                label="E-mailadres"
                addonContent="✉️"
                addonSide="start"
                onChange={e => handleChange({ email: e.target.value })}
                value={formState.email.value}
                touched={formState.email.touched}
                error={formState.email.error}
                autoComplete="email"
                inputMode="email"
              />
            </Box>
          </Flex>

          <MarketingConsentCheckbox
            checked={formState.marketingConsent.value}
            onToggle={v => handleChange({ marketingConsent: v })}
            error={formState.marketingConsent.error}
            mt={1}
          />

          <Button
            type="submit"
            bgColor="yellow"
            color="grayBlack"
            disabled={!formState.email.value || !formState.firstName.value || emailExists}
            fluid
            loading={loading}
            loadTime={2}
            style={{ borderRadius: 3, whiteSpace: 'nowrap' }}
            error={errors.general}
            label="Ga verder →"
          />
        </form>
      )}
      {!magicLinkRequested && (
        <Center block>
          <Button
            type="button"
            bgColor="grayDarker"
            minimal
            fontSize={7}
            style={{ borderRadius: 3, whiteSpace: 'nowrap' }}
            mt={1}
            mb={0}
            px={5}
            label={hasExistingAccount ? 'Ik heb nog geen account' : 'Ik heb al een account'}
            onClick={() => setHasExistingAccount(!hasExistingAccount)}
          />
        </Center>
      )}
    </Card>
  );
};

export default MinimalSignupForm;
