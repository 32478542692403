import { useLazyQuery } from '@apollo/client';
import { HOUSE_TYPES_NL } from '@energiebespaarders/constants';
import useWindowSize from '@energiebespaarders/hooks/useWindowSize';
import { Box, Flex, Icon, Tooltip } from '@energiebespaarders/symbols';
import { Color, FontSize, FontWeight, Small } from '@energiebespaarders/symbols/helpers';
import { CaretRight, Information } from '@energiebespaarders/symbols/icons/solid';
import { breakpointsPx } from '@energiebespaarders/symbols/styles/breakpoints';
import React, { useEffect } from 'react';
import {
  StyledDescription,
  StyledEuro,
  StyledPackageButton,
  StyledPackageCard,
  StyledPackageTitle,
  StyledPrice,
} from '~/components/RequestPackage/PackageCard';
import PackageRibbon from '~/components/RequestPackage/PackageRibbon';
import { useActiveHouseId } from '~/hooks/useActiveHouseId';
import { LEAD_HOUSE_BY_ID } from '~/queries/house';
import { themify } from '~/styles/mixins';
import { leadHouseById, leadHouseByIdVariables } from '~/types/generated/leadHouseById';
import { DutyPackageWithPrice } from '~/utils/dutyPackages';
import { determineLabelPriceByHouseType } from './utils';

interface EnergyLabelPackageCardProps {
  packageProps: DutyPackageWithPrice;
  setActive: () => void;
  houseType?: number;
  hideButton?: boolean;
}

export const EnergyLabelPackageCard: React.FC<EnergyLabelPackageCardProps> = ({
  packageProps,
  setActive,
  houseType,
  hideButton,
}) => {
  const { activeHouseId } = useActiveHouseId();
  const windowWidth = useWindowSize().width;
  const desktop = windowWidth >= breakpointsPx.lg;

  const [getLeadHouse, { data, loading, error }] = useLazyQuery<
    leadHouseById,
    leadHouseByIdVariables
  >(LEAD_HOUSE_BY_ID, { fetchPolicy: 'cache-first' });

  useEffect(() => {
    if (activeHouseId && !loading && !houseType && !error)
      void getLeadHouse({ variables: { id: activeHouseId } });
  }, [getLeadHouse, loading, activeHouseId, houseType, error]);

  const activeHouse = data?.house || undefined;
  const _houseType = houseType || activeHouse?.type;

  const isApartment = _houseType && _houseType >= 5 && _houseType < 22;
  const isRowHouse = _houseType === 3 || _houseType === 4;
  const isDuplexHouse = _houseType === 2 || _houseType === 22;
  const isDetachedHouse = _houseType === 1;

  return (
    <StyledPackageCard $bgColor={packageProps.color} color="grayDarker" mb={3} px={0}>
      {packageProps.ribbon && (
        <PackageRibbon bgColor={packageProps.ribbonColor || 'green'} text={packageProps.ribbon} />
      )}
      <Flex flexWrap="wrap" pt={2}>
        <Box width={1} px={[1, 2, 2, 3]} textAlign="center">
          <StyledPackageTitle heading="h3" letterSpacing="-0.025em">
            {packageProps.fullName}{' '}
          </StyledPackageTitle>
          <StyledDescription style={{ minHeight: '2em' }}>
            Laat het energielabel van je woning opnemen en registreren
          </StyledDescription>
        </Box>

        <Box width={1} px={[1, 2, 2, 3]} textAlign="center" mb={1}>
          <Flex justifyContent="center" alignItems="flex-end">
            {houseType ? (
              <>
                <StyledPrice>
                  {determineLabelPriceByHouseType(houseType)}
                  <StyledEuro>€</StyledEuro>
                </StyledPrice>
                <Tooltip
                  content={`De prijs verschilt per woningtype. Dit is de prijs voor een
                      ${HOUSE_TYPES_NL[houseType].toLowerCase()}.`}
                >
                  <Icon icon={Information} fill="blue" verticalAlign="middle" m={1} />
                </Tooltip>
              </>
            ) : (
              <Box mr={1}>
                <Small>
                  <p>
                    De prijs verschilt per woningtype{!_houseType ? '. Vul eerst je adres in.' : ''}
                  </p>
                </Small>
                <div>
                  <Color c={isApartment || !_houseType ? 'grayDarker' : 'gray'}>
                    {!!isApartment && <Small>▶ </Small>}
                    <FontSize scale={isApartment ? 5 : 6}>
                      <FontWeight weight={isApartment ? '600' : '400'}>
                        Appartement: €{determineLabelPriceByHouseType(5)}
                      </FontWeight>
                    </FontSize>
                  </Color>
                </div>
                <div>
                  <Color c={isRowHouse || !_houseType ? 'grayDarker' : 'gray'}>
                    {isRowHouse && <Small>▶ </Small>}
                    <FontSize scale={isRowHouse ? 4 : 6}>
                      <FontWeight weight={isRowHouse ? '600' : '400'}>
                        Rijwoning: €{determineLabelPriceByHouseType(4)}
                      </FontWeight>
                    </FontSize>
                  </Color>
                </div>
                <div>
                  <Color c={isDuplexHouse || !_houseType ? 'grayDarker' : 'gray'}>
                    {isDuplexHouse && <Small>▶ </Small>}
                    <FontSize scale={isDuplexHouse ? 5 : 6}>
                      <FontWeight weight={isDuplexHouse ? '600' : '400'}>
                        2-onder-1-kapwoning: €{determineLabelPriceByHouseType(2)}
                      </FontWeight>
                    </FontSize>
                  </Color>
                </div>
                <div>
                  <Color c={isDetachedHouse || !_houseType ? 'grayDarker' : 'gray'}>
                    {isDetachedHouse && <Small>▶ </Small>}
                    <FontSize scale={isDetachedHouse ? 5 : 6}>
                      <FontWeight weight={isDetachedHouse ? '600' : '400'}>
                        Vrijstaande woning: €{determineLabelPriceByHouseType(1)}
                      </FontWeight>
                    </FontSize>
                  </Color>
                </div>
              </Box>
            )}
          </Flex>
        </Box>

        <Box width={1} px={[1, 2, 2, 3]} mt={2} mb={3} textAlign="center">
          {!hideButton && (
            <StyledPackageButton
              bgColor={themify(packageProps.color)}
              fluid
              fontSize={desktop ? 5 : 6}
              iconEnd={CaretRight}
              label={packageProps.callToAction}
              mb={3}
              onClick={setActive}
            />
          )}
        </Box>
      </Flex>
    </StyledPackageCard>
  );
};

export default EnergyLabelPackageCard;
